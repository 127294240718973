import { Tonality } from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { convertDateToLocalTime } from 'src/utils/Formatter';
import {
  ComboboxInputStyles,
  ComboboxPopoverStyles,
  ComboboxStyles,
  ComboboxWrapper,
  InlineNote,
  InputLabelStyles,
  StyledDayPicker,
} from './StartTrackingSelection.styles';
import { AddTrackList } from '../MultiAsset.slice';

type StartTrackingProps = {
  displayDate: Date | string;
  handleStartDate: (date: Date) => void;
  isCasterRelase: boolean;
};

const StartTrackingSelection: React.FC<StartTrackingProps> = ({
  displayDate,
  handleStartDate,
  isCasterRelase,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { releaseTrack }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling disable date
  const getDisableDate = (days?: number) => {
    const date = new Date();
    if (days) {
      date.setDate(date.getDate() + days);
    }
    return date;
  };

  // Handling date selection
  const handleDateSelection = (date: Date) => {
    handleStartDate(date);
    setOpen(false);
  };

  // Handling onchange
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDateSelection(new Date(e.target.value));
  };

  return (
    <>
      {/* Start Tracking Selection - Day Picker */}
      <ComboboxWrapper>
        <Tonality.Combobox
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          label={t('MULTI_ASSET_START_TRACKING_DATE')}
          value={
            displayDate === ''
              ? displayDate
              : format(displayDate as Date, 'MMMM dd, yyyy')
          }
          style={{ ...ComboboxStyles }}
          setting="mtr"
        >
          <Tonality.ComboboxInput
            style={{ ...ComboboxInputStyles }}
            tooltip={t('MULTI_ASSET_START_TRACKING_DATE_TOOLTIP')}
            required
            hasError={false}
            inputLabelStyles={{ ...InputLabelStyles }}
          />
          <Tonality.ComboboxPopover style={{ ...ComboboxPopoverStyles }}>
            <StyledDayPicker
              fromMonth={getDisableDate()}
              toMonth={getDisableDate(30)}
              disabled={[
                { before: getDisableDate() },
                { after: getDisableDate(30) },
              ]}
              selected={[new Date(displayDate)]}
              onDayClick={(nextDay) => {
                handleDateSelection(nextDay);
              }}
            />
          </Tonality.ComboboxPopover>

          {/* Inline Note */}
          {isCasterRelase ? (
            /* 2. Caster songs */
            <InlineNote>
              <Trans
                i18nKey="IMPORT_RELEASE_START_TRACKING_INLINE_NOTE"
                values={{
                  releaseDate: format(
                    convertDateToLocalTime(
                      new Date(Date.parse(releaseTrack?.release_date))
                    ),
                    'MMM dd, yyyy'
                  ),
                }}
              />
            </InlineNote>
          ) : (
            /* 1. Default flow */
            <InlineNote>
              {t('MULTI_ASSET_START_TRACKING_INLINE_NOTE')}
            </InlineNote>
          )}
        </Tonality.Combobox>
      </ComboboxWrapper>
    </>
  );
};

export default StartTrackingSelection;
