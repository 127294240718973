/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialIcon, PaymentIcon, Text } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { makeFirstLetterUpperCase } from 'src/utils/Formatter';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { v4 as uuidv4 } from 'uuid';
import { IconNames } from '@dsny/dsny-component-library/dist/components/Tonality/Icons/PaymentIcon/PaymentIcon';
import { DeleteButton, DeleteButtonWrapper } from '../Purchase.styles';
import { CardIconWrapper, CardInfo, EditCardWrapper } from './SavedCard.styles';
import { RemoveCardModal } from './RemoveCardModal';
import { deleteSavedCard } from '../AddPayment';

interface Props {
  isCardExpired: () => boolean;
}

const SavedCard: React.FC<Props> = ({ isCardExpired }) => {
  const { t } = useTranslation();
  const [showRemoveCard, setShowRemoveCard] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { currentPaymentMethod } = useSelector(
    (state: RootState) => state.addPayment
  );

  // Handling remove saved card
  const removeSavedCard = () => {
    dispatch(
      deleteSavedCard({
        identifier: uuidv4(),
        payment_method_id: currentPaymentMethod.id,
      })
    );
  };

  return (
    <>
      {/* Saved card */}
      <Text
        fontSize="16px"
        fontWeight={700}
        color={theme.colors.neutralW20}
        style={{ paddingBottom: '16px' }}
      >
        {t('ADD_PAYMENT_CARD_DETAILS')}
      </Text>
      <EditCardWrapper>
        <CardIconWrapper>
          {/* Credit Card Icon */}
          <PaymentIcon
            name={
              makeFirstLetterUpperCase(
                currentPaymentMethod?.brand
              ) as keyof typeof IconNames
            }
          />
          <CardInfo $isCardExpired={isCardExpired()}>
            **** {currentPaymentMethod.last4}
            <p>
              {t('SUMMARY_EXPIRY')} {currentPaymentMethod.exp_month}/
              {currentPaymentMethod.exp_year}
            </p>
          </CardInfo>
        </CardIconWrapper>

        {/* Delete card button */}
        <DeleteButtonWrapper>
          <DeleteButton onClick={() => setShowRemoveCard(true)}>
            <MaterialIcon name="Trash" color={theme.colors.neutralW40} />
          </DeleteButton>
        </DeleteButtonWrapper>
      </EditCardWrapper>

      {/* Modal - remove card */}
      <RemoveCardModal
        show={showRemoveCard}
        setShowRemoveCard={setShowRemoveCard}
        removeSavedCard={removeSavedCard}
      />
    </>
  );
};

export default SavedCard;
