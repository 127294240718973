import React from 'react';
import { AccountBadgeButton } from './AccountBadges.styles';

interface AccountBadgesProps {
  onClick?: () => void;
  avatarColor: string;
  avatarInitals: string;
}

const AccountBadges: React.FC<AccountBadgesProps> = ({
  onClick,
  avatarColor,
  avatarInitals,
}) => {
  return (
    // Account Badge
    // TODO: will be added to Storybook
    <AccountBadgeButton avatarColor={avatarColor} onClick={onClick}>
      {avatarInitals}
    </AccountBadgeButton>
  );
};

export default AccountBadges;
