/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialIcon, Pill } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { stationsSortKeys } from 'src/features/Dashboard';
import {
  SortButtonWrapper,
  FlexRowHeader,
  GenresColumn,
  CityColumn,
  LogoColumn,
  StationNameColumn,
  TitleWrapper,
  GenreWrapper,
  CountryColumn,
} from './RadioStationList.styles';
import {
  addSearchParams,
  hideOrShowAllGenres,
} from '../RadioStationModal.slice';

const RadioStationHeader: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [sortByName, setSortByName] = useState('Unfold');
  const [sortByCity, setSortByCity] = useState('Unfold');
  const [sortByCountry, setSortByCountry] = useState('Unfold');
  // const [sortByGenres, setSortByGenres] = useState('Unfold');

  const { radioStationTable } = useSelector(
    (state: RootState) => state.radioStationModal.radioStationList
  );

  const { searchParams, clearAllFilters } = useSelector(
    (state: RootState) => state.radioStationModal
  );

  // Expand all or Collapse all - When the expand or collpase any radio stations with more than 3 genres will expand or collapse to reveal/hide all genres. This button acts as a global control.
  const expandCollapseAll = () => {
    dispatch(hideOrShowAllGenres());
  };

  // Handling sort parameter
  const findSortParam = (name: string, icon: string) => {
    if (icon === 'UnfoldAscending') {
      return name;
    }

    if (icon === 'UnfoldDescending') {
      return `-${name}`;
    }

    return '';
  };

  useEffect(() => {
    setSortByName('Unfold');
    setSortByCity('Unfold');
    setSortByCountry(`Unfold`);
    // setSortByGenres('Unfold');
  }, [clearAllFilters]);

  // Handing sorting by name
  const sortListByName = (iconName: string) => {
    const key = stationsSortKeys.get(iconName) as any;
    setSortByName(key);
    setSortByCity('Unfold');
    setSortByCountry('Unfold');
    // setSortByGenres('Unfold');
    const tempSearch = searchParams;
    dispatch(
      addSearchParams({
        ...tempSearch,
        page: 1,
        sort: findSortParam('name', key),
        hasNext: true,
      })
    );
  };

  // Handing sorting by city
  const sortListByCity = (iconName: string) => {
    const key = stationsSortKeys.get(iconName) as any;
    setSortByName('Unfold');
    setSortByCity(key);
    setSortByCountry('Unfold');
    // setSortByGenres('Unfold');
    const tempSearch = searchParams;
    dispatch(
      addSearchParams({
        ...tempSearch,
        page: 1,
        sort: findSortParam('city', key),
        hasNext: true,
      })
    );
  };

  // Handing sorting by country
  const sortListByCountry = (iconName: string) => {
    const key = stationsSortKeys.get(iconName) as any;
    setSortByName('Unfold');
    setSortByCity('Unfold');
    setSortByCountry(key);
    // setSortByGenres('Unfold');
    const tempSearch = searchParams;
    dispatch(
      addSearchParams({
        ...tempSearch,
        page: 1,
        sort: findSortParam('country', key),
        hasNext: true,
      })
    );
  };

  // TODO: Handling sorting by genre will be implemented later
  // const sortListByGenres = (iconName: string) => {
  //   const key = stationsSortKeys.get(iconName) as any;
  //   setSortByName('Unfold');
  //   setSortByCity('Unfold');
  //   setSortByCountry('Unfold');
  //   setSortByGenres(key);
  //   const tempSearch = searchParams;
  //   dispatch(
  //     addSearchParams({
  //       ...tempSearch,
  //       page: 1,
  //       sort: findSortParam('genre', key),
  //       hasNext: true,
  //     })
  //   );
  // };

  return (
    <>
      {/* Table Header */}
      <FlexRowHeader>
        <LogoColumn>
          <TitleWrapper>#</TitleWrapper>
        </LogoColumn>

        <StationNameColumn>
          <TitleWrapper>
            {t('STATIONS_NAME_HEADER')}
            <SortButtonWrapper onClick={() => sortListByName(sortByName)}>
              <MaterialIcon name={sortByName as any} />
            </SortButtonWrapper>
          </TitleWrapper>
        </StationNameColumn>

        <CityColumn>
          <TitleWrapper>
            {t('STATIONS_CITY_HEADER')}
            <SortButtonWrapper onClick={() => sortListByCity(sortByCity)}>
              <MaterialIcon name={sortByCity as any} />
            </SortButtonWrapper>
          </TitleWrapper>
        </CityColumn>

        <CountryColumn>
          <TitleWrapper>
            {t('STATIONS_COUNTRY_HEADER')}
            <SortButtonWrapper onClick={() => sortListByCountry(sortByCountry)}>
              <MaterialIcon name={sortByCountry as any} />
            </SortButtonWrapper>
          </TitleWrapper>
        </CountryColumn>

        <GenresColumn>
          <GenreWrapper>
            <TitleWrapper>
              {t('STATIONS_GENRES_PLAYED_HEADER')}
              {/* <SortButtonWrapper onClick={() => sortListByGenres(sortByGenres)}>
                <MaterialIcon name={sortByGenres as any} />
              </SortButtonWrapper> */}
            </TitleWrapper>
            <Pill
              label={
                radioStationTable?.showAllGenres
                  ? t('STATIONS_COLLAPSE_ALL')
                  : t('STATIONS_EXPAND_ALL')
              }
              onClick={expandCollapseAll}
              variant="outlined"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '16px',
                color: theme.colors.mtr60,
                borderRadius: '21px',
                border: '1px solid #7D7FE8',
                background: theme.colors.mtr100,
                display: 'inline-flex',
                width: 'fit-content',
                minWidth: 'fit-content',
              }}
            />
          </GenreWrapper>
        </GenresColumn>
      </FlexRowHeader>
    </>
  );
};

export default RadioStationHeader;
