import { device, mtrStyle } from 'src/styles/global';
import styled from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  isOpen: boolean;
}

export const NavContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${theme.colors
    .white0}; // TODO: set neutralC100 with sidebar implementation
  position: fixed;
  top: 0;
  width: 100%;
  padding: 24px;
  z-index: 1001;

  @media ${device.mobile} {
    background: ${theme.colors.white0};
    padding: 16px;
    border-radius: 0px 0px 5px 5px;
    border-bottom: 1px solid ${theme.colors.mtr60};
    box-shadow: ${({ isOpen }) =>
      isOpen ? `0px 0px 8px 0px rgba(0, 0, 0, 0.25)` : 'none'};
    border-bottom: ${({ isOpen }) =>
      isOpen ? `1px solid ${theme.colors.mtr60}` : 'none'};
  }

  @media ${device.tablet} {
    padding: 16px;
  }

  @media ${device.ipad} {
    padding: 16px;
  }
`;

export const SidebarMobile = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 20px;
    background: ${theme.colors.mtr60};
    margin-bottom: 4px;
    border-radius: ${mtrStyle.borderRadius};
  }

  @media ${device.mobile} {
    display: flex;
  }
`;

export const LogoWrapper = styled.div`
  svg {
    width: 153px;
    height: 54px;
  }

  @media ${device.mobile} {
    svg {
      width: 110px;
      height: 40px;
    }
  }
`;

export const AccountBadgeMobileWrapper = styled.div`
  display: none;

  @media ${device.mobile} {
    display: flex;
  }
`;

export const Menu = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
    overflow: ${({ isOpen }) => (isOpen ? '' : 'hidden')};
    transition: max-height 0.3s ease-in;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;
    gap: 0px;
  }

  @media ${device.tablet} {
    align-items: center;
    gap: 16px;
  }

  @media ${device.ipad} {
    align-items: center;
    gap: 16px;
  }
`;

export const AccountInfoWrapper = styled.div`
  display: none;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.neutralW100};
  width: 100%;
  gap: 16px;

  @media ${device.mobile} {
    display: flex;
  }
`;

export const AccountNameEmailWrapper = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media ${device.mobile} {
    display: flex;
  }
`;

export const AccountNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TeamSpaceDropdownMobile = styled.div`
  display: none;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: flex;
  }
`;

export const TeamSpaceButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  gap: 8px;
`;

export const TeamSpaceDescMobile = styled.div<Props>`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const PromoPriceButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 16px;
  color: ${theme.colors.mtr60};
  border: none;
  background: none;
  cursor: pointer;
  padding: 16px 0px 16px;
  gap: 8px;
`;

export const PricingButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 16px 0px 16px;
  gap: 8px;
`;

export const StationListButtonMobile = styled.button`
  display: none;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 16px 0px 16px;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: flex;
  }
`;

export const PreferencesButtonMobile = styled.button`
  display: none;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 16px 0px 16px;
  gap: 8px;

  svg {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: flex;
  }
`;

export const SignoutButtonMobile = styled.button`
  display: none;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  gap: 8px;
  width: 100%;
  padding-top: 16px;
  border-top: 1px solid ${theme.colors.neutralW100};

  svg {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    display: flex;
  }
`;
