import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Arrowdropdown,
  Arrowdropup,
  Close,
  Sell,
} from '@dsny/dsny-component-library/icons';
import { Pill, TextField } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  ApplyButton,
  CodeTagWrapper,
  DiscountCodeWrapper,
  EnterPromoCodeDropdown,
  FieldsWrapper,
  PillWrapper,
} from './DiscountCode.styles';
import { getPackageList, getPromoCodeDiscount } from '../../SelectPackage';

interface Promotion {
  name: string;
  discountPercentage: number;
  promoCodeActive: string;
}

const DiscountCode: React.FC = () => {
  const { t } = useTranslation();
  const [isInputVisible, setInputVisible] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeErrorText, setPromoCodeErrorText] = useState('');
  const [currentPromotion, setCurrentPromotion] = useState<Promotion>({
    name: '',
    discountPercentage: 0,
    promoCodeActive: '',
  });
  const dispatch = useDispatch<AppDispatch>();

  const { selectedPackage, promoCodeError, promoCodeActive, isFetching } =
    useSelector((state: RootState) => state.selectPackage);

  useEffect(() => {
    if (
      selectedPackage?.promotion !== undefined &&
      selectedPackage?.promotion !== null
    ) {
      const { name, discount_percentage: discountPercentage } =
        selectedPackage.promotion;
      setCurrentPromotion({
        name,
        discountPercentage,
        promoCodeActive,
      });
    }
  }, [selectedPackage, promoCodeActive]);

  useEffect(() => {
    if (promoCodeActive !== '') {
      setPromoCode('');
    }
  }, [promoCodeActive]);

  useEffect(() => {
    setPromoCodeErrorText(t(promoCodeError));
  }, [promoCodeError]);

  const toggleInputVisibility = () => {
    setInputVisible((prev) => !prev);
  };

  // Handling promo code
  const handlePromoCode = (event: ChangeEvent<Element>) => {
    const inputElement = event.target as HTMLInputElement;
    setPromoCode(inputElement.value.trim().toUpperCase());

    if (inputElement.value === currentPromotion.promoCodeActive) {
      setPromoCodeErrorText(t('DISCOUNT_CODE_ERROR_CODE_IN_USE'));
    } else {
      setPromoCodeErrorText('');
    }
  };

  // Handling remove promo code
  const handleRemovePromoCode = () => {
    if (selectedPackage?.summary?.quantity) {
      dispatch(getPackageList(selectedPackage?.summary?.quantity));
      setPromoCodeErrorText('');
    }
  };

  // Handling form submition
  const submitPromoCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedPackage?.summary?.quantity) {
      dispatch(
        getPromoCodeDiscount({
          quantity: selectedPackage?.summary?.quantity,
          promoCode,
        })
      );
    }
  };

  return (
    <DiscountCodeWrapper>
      <EnterPromoCodeDropdown onClick={toggleInputVisibility}>
        {t('DISCOUNT_CODE_ENTER_PROMO_CODE')}
        {isInputVisible ? <Arrowdropup /> : <Arrowdropdown />}
      </EnterPromoCodeDropdown>
      <form onSubmit={submitPromoCode}>
        <FieldsWrapper $isVisible={isInputVisible}>
          <TextField
            setting="mtr"
            id="discount-code"
            name="discountCode"
            labelText={t('DISCOUNT_CODE')}
            value={promoCode}
            onChange={(e: ChangeEvent) => handlePromoCode(e)}
            error={promoCode !== '' && promoCodeErrorText}
            wrapperStyles={{ marginBottom: 0, paddingRight: '0px' }}
            inputStyle={{
              textTransform: 'uppercase',
              borderColor:
                promoCode !== '' &&
                !!promoCodeErrorText &&
                theme.colors.error50,
            }}
            labelStyle={{
              fontSize: '14px',
              color:
                promoCode !== '' &&
                !!promoCodeErrorText &&
                theme.colors.error50,
            }}
            errorStyle={{
              overflow: 'visible',
              marginTop: 8,
              color: theme.colors.error50,
            }}
            inputRef={() => ''}
          />
          <ApplyButton
            setting="mtr"
            type="submit"
            disabled={
              promoCode === '' ||
              promoCode === currentPromotion.promoCodeActive ||
              isFetching
            }
            style={{ height: 44 }}
          >
            {t('DISCOUNT_CODE_APPLY_CTA')}
          </ApplyButton>
        </FieldsWrapper>
      </form>
      {!!promoCodeActive && (
        <CodeTagWrapper>
          <PillWrapper>
            <Pill
              variant="tonal"
              icon={<Sell />}
              label={promoCodeActive}
              disabled
              style={{ pointerEvents: 'none', textTransform: 'uppercase' }}
            />
          </PillWrapper>
          <Pill
            variant="tonal"
            label=""
            icon={<Close />}
            style={{
              margin: 0,
              marginLeft: -8,
              padding: 4,
              paddingLeft: 8,
            }}
            onClick={() => handleRemovePromoCode()}
          />
        </CodeTagWrapper>
      )}
    </DiscountCodeWrapper>
  );
};

export default DiscountCode;
